<template>
    <component :is="layout">
        <Snackbar :attr="snackbarAttr"/>
        <router-view></router-view>
    </component>
</template>

<style lang="css">
html {
  overflow-y: auto; /* reference: https://vuetifyjs.com/en/getting-started/frequently-asked-questions/ "The scrollbar is showing even though my content is not overflowing vertically" */
}
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>

<script>
// import { mapState } from 'vuex';
// import '@/assets/font/NunitoSans/NunitoSans.css'
import Snackbar from '@/components/Snackbar.vue';

export default {
    name: 'App',

    components: {
        Snackbar,
    },

    data: () => ({
        snackbarAttr: {},
    }),

    computed: {
        layout() {
            return this.$route.meta.layout ?? 'main-layout';
        },
    },

    created() {
        console.log('app.vue: created, initializing');
        this.$store.dispatch('init');

        // call when tab is selected
        window.onfocus = () => {
            this.$store.dispatch('refresh');
            // this.$bus.$emit('window-focus');
            this.$store.commit('focus', Date.now()); // any components that want to refresh on focus can watch for changes to this value
        };

        // listen for snackbar events coming from any component
        this.$bus.$on('snackbar', (attr) => { this.snackbarAttr = attr; });
    },
};
</script>
