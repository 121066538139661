import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/brand/:alias',
            name: 'brand',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand" */ '../views/brand/Main.vue'),
        },
        {
            path: '/brand/:alias/authenticate',
            name: 'brand-authenticate',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand-authenticate" */ '../views/brand/Authenticate.vue'),
        },
        {
            path: '/brand/:alias/interaction',
            name: 'brand-interaction',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand-interaction" */ '../views/brand/Interaction.vue'),
        },
        {
            path: '/brand/:alias/preferences',
            name: 'brand-preferences',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand-preferences" */ '../views/brand/Preferences.vue'),
        },
        {
            path: '/brand/:alias/subscribe',
            name: 'brand-subscribe',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand-subscribe" */ '../views/brand/Subscribe.vue'),
        },
        {
            path: '/brand/:alias/unsubscribe',
            name: 'brand-unsubscribe',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand-unsubscribe" */ '../views/brand/Unsubscribe.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
