<template>
    <v-app>
    <!-- <Navbar/> -->
    <v-main style="height: 100%;" :style="backgroundStyle" class="ma-0 print">
        <v-container class="ma-0 pa-0" fluid style="height: 100%;">
        <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
            <template v-if="!isViewReady">
                <v-row justify="center" class="py-5" align="center" style="height: 100%">
                    <v-col cols="12" sm="6">
                    <div class="text-center">
                    <v-progress-circular indeterminate width="2" color="grey darken-2"/>
                    </div>
                    </v-col>
              </v-row>
            </template>
            <template v-if="isViewReady && !brand">
                <v-row justify="center" class="py-5" align="center" style="height: 100%">
                    <v-col cols="12" sm="6">
                    <v-card>
                    <v-card-title>
                    Not found
                    </v-card-title>
                    <v-card-text>
                    <p class="mt-8">
                    Are you trying to manage your email communication preferences?
                    </p>
                    <p>
                    <router-link :to="{ name: 'front' }">Search for a brand</router-link>
                    </p>
                    </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-if="isViewReady && brand">
                <slot/> <!--  :brand="brand"  ? not working -->
            </template>
          </v-col>
          </v-row>
      </v-container>
    </v-main>
    <Footer/>
    <!-- TODO: move all these "Powered by Emplus" footers to an actual footer maybe at the bottom of the page -->
    <!-- <p class="mt-12"><a :href="mainWebsiteURL">Powered by Emplus</a></p> -->
    </v-app>
</template>

<script>
import { mapState } from 'vuex';
// import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: 'MainLayout',
    components: {
        // Navbar,
        Footer,
    },
    data: () => ({
        isViewReady: false,
    }),
    computed: {
        ...mapState({
            brand: (state) => state.brand,
            palette: (state) => state.palette,
        }),
        backgroundStyle() {
            const defaultBackgroundColor = '#efefef'; // light grey
            /*
            let backgroundColor;
            if (this.brand && Array.isArray(this.palette?.content?.background)) {
                backgroundColor = this.palette.content.background[0].hex;
            } else {
                backgroundColor = defaultBackgroundColor;
            }
            // TODO: input validation that backgroundColor is a valid hex color value or html color name
            return `background-color: ${backgroundColor}`;
            */
            return `background-color: ${defaultBackgroundColor}`;
        },
    },
    watch: {
        $route(newValue, oldValue) {
            if (newValue?.params?.alias !== oldValue?.params?.alias) {
                console.log(`detected change from ${oldValue?.params?.alias} to ${newValue?.params?.alias}`);
                this.loadBrand();
            }
        },
    },
    methods: {
        async loadBrand() {
            await this.$store.dispatch('loadBrand', { alias: this.$route.params.alias, mode: 'light', media_type: 'screen' });
            await this.$store.dispatch('loadPalette', { alias: this.$route.params.alias, mode: 'light', media_type: 'screen' });
            this.isViewReady = true;
        },
    },
    mounted() {
        this.loadBrand();
    },
};
</script>

<style lang="css">
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
