import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        brand: null,
        palette: null,
        session: {},
        /*
        We use a default palette with greys to minimize the flicker that
        happens when the actual brand palette is finally loaded; if we
        use Emplus brand colors, the flicker is very noticeable
        */
        defaultPalette: {
            text: '#000000', // black
            primary: '#9E9E9E', // grey
            primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        loadingMap: { init: true },
        // noteList: [],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
        isAuthenticatedReady(state) {
            return Array.isArray(state.session.email); // starts as null, then after we load session from server it will be an array
        },
        brandName(state) {
            return state.brand?.name ?? ''; // 'Loading...';
        },
        primaryColor(state) {
            let result;
            if (state.brand && Array.isArray(state.palette?.content?.primary)) {
                result = state.palette.content.primary[0].hex;
            } else {
                result = state.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor(state) {
            return state.defaultPalette.primaryText;
        },
        accentColor(state) {
            let result;
            if (state.brand && Array.isArray(state.palette?.content?.accent)) {
                result = state.palette.content.accent[0].hex;
            } else {
                result = state.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle(state, getters) {
            return `color: ${getters.primaryTextColor}`;
        },
        cardTitleBarStyle(state, getters) {
            return `background-color: ${getters.primaryColor}`;
        },
        primaryButtonStyle(state, getters) {
            return `color: ${getters.primaryTextColor}; background-color: ${getters.primaryColor};`;
        },
        primaryIconStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
    },
    mutations: {
        ready(state) {
            state.isReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        brand(state, brand) {
            state.brand = brand;
        },
        palette(state, palette) {
            state.palette = palette;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        // async createAccount({ commit, dispatch, state }, accountInfo) {
        //     commit('loading', { createAccount: true });
        //     const response = await client.user.create(accountInfo);
        //     if (response.isCreated) {
        //         await dispatch('loadSession');
        //         if (state.session.isAuthenticated) {
        //             await dispatch('loadUser');
        //             // await dispatch('loadAccount');
        //         }
        //     }
        //     commit('loading', { createAccount: false });
        //     return response;
        // },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { email: [] });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.main().authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    // dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        // TODO: need to adapt this for contact service; or is it not needed?
        // async loadServiceInfo({ commit }) {
        //     commit('loading', { loadServiceInfo: true });
        //     try {
        //         const [/* versionInfo, */serviceInfo] = await Promise.all([
        //             // client.service.getVersion(),
        //             client.main().service.getInfo(),
        //         ]);
        //         // commit('setServiceVersion', versionInfo);
        //         commit('setServiceInfo', serviceInfo);
        //     } catch (err) {
        //         console.error('vuex store: failed to load service info');
        //     }
        //     commit('loading', { loadServiceInfo: false });
        // },
        // TODO: need to adapt this for contact service
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    email = [],
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (Number.isInteger(refreshAfterDuration) && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                // console.log(`vuex store: commit session for email ${email}`);
                commit('setSession', {
                    email,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                });
            } catch (err) {
                console.error('vuex store: failed to set session', err);
                commit('setSession', { fault: { type: 'read-failed' } });
            } finally {
                commit('loading', { loadSession: false });
            }
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.main().authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        // TODO: are we still using this here?
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        // TODO: are we still using this here?
        async loadInteraction({ commit }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.main().interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        async loadBrand({ commit }, { alias }) {
            try {
                commit('loading', { loadBrand: true });
                const response = await client.brandprofile().brand.get({ alias });
                commit('brand', response);
            } catch (err) {
                console.error('loadBrand failed', err);
                commit('brand', null);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPalette({ commit }, { alias, mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                const match = {
                    alias,
                    mode,
                };
                const response = await client.brandprofile().palette.get(match);
                commit('palette', response);
            } catch (err) {
                console.error('loadPalette failed', err);
                commit('palette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
    },
});
